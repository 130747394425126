<template>
  <div>
    <el-dialog
      title="新增回访级别"
      :visible.sync="addStatus"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <!-- //档案类别单选框(radio),购买状态单选框(radio),且都是必填字段 -->
        <el-form-item
          label="线索类型"
          prop="source_type"
          :label-width="formLabelWidth"
        >
          <el-radio-group v-model="form.source_type">
            <el-radio :label="1">电商</el-radio>
            <el-radio :label="2">表单</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="档案类别"
          prop="m_category"
          :label-width="formLabelWidth"
        >
          <el-radio-group v-model="form.m_category">
            <el-radio :label="1">采线</el-radio>
            <el-radio :label="2">运营</el-radio>
            <el-radio :label="3">回访</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="购买状态"
          prop="buy_status"
          :label-width="formLabelWidth"
        >
          <el-radio-group v-model="form.buy_status">
            <el-radio :label="1">未购</el-radio>
            <el-radio :label="2">已购</el-radio>
          </el-radio-group>
        </el-form-item>
	
				
        <el-form-item
          label="使用状态"
          prop="use_status"
          :label-width="formLabelWidth"
          v-if="form.buy_status == 2"
        >
          <el-radio-group v-model="form.use_status">
            <el-radio :label="1">在用</el-radio>
            <el-radio :label="2">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="回访级别"
          prop="short_name"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.short_name"></el-input>
        </el-form-item>
        <el-form-item
          label="级别含义"
          prop="name"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="间隔天数"
          prop="interval_days"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.interval_days"></el-input>
        </el-form-item>
        <el-form-item
          label="下次内容"
          prop="next_content"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.next_content"></el-input>
        </el-form-item>
        <el-form-item
          label="回收次数"
          prop="recovery_count"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.recovery_count"></el-input>
        </el-form-item>
        <!--<el-form-item-->
        <!--  label="结果"-->
        <!--  prop="result"-->
        <!--  :label-width="formLabelWidth"-->
        <!--&gt;-->
        <!--  <el-input v-model="form.result"></el-input>-->
        <!--</el-form-item>-->
	
				<el-form-item
					label="排序"
					prop="sort"
					:label-width="formLabelWidth"
				>
					<el-input v-model="form.sort"></el-input>
				</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addStatus = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postVisitLevel } from "@/api/rank/rank";
export default {
  name: "addCallbackLevel",
  data() {
    return {
      addStatus: false,
      form: {
        source_type: 1,
        m_category: 1,
        use_status: 1, //使用状态
        buy_status: 1, //顾客状态
        short_name: "", //回访级别
        name: "", //级别含义
        interval_days: "", //间隔天数
        next_content: "", //下次内容
        recovery_count: "", //回收次数
        result: "", //结果
        sort: "", //排序
      },
      formLabelWidth: "120px",
      rules:{
        source_type: [
          { required: true, message: "请选择线索类型", trigger: "change" },
        ],
        m_category: [
          { required: true, message: "请选择线索类型", trigger: "change" },
        ],
        use_status: [
          { required: true, message: "请选择线索类型", trigger: "change" },
        ],
        buy_status: [
          { required: true, message: "请选择线索类型", trigger: "change" },
        ],
        short_name: [
          { required: true, message: "请输入回访级别", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入级别含义", trigger: "blur" },
        ],
        interval_days: [
          { required: true, message: "请输入间隔天数", trigger: "blur" },
          {pattern: /^[0-9]*$/, message: '间隔天数需为数字', trigger: 'blur'}
        ],
        next_content: [
          { required: true, message: "请输入下次内容", trigger: "blur" },
        ],
        recovery_count: [
          { required: true, message: "请输入回收次数", trigger: "blur" },
          {pattern: /^[0-9][0-9]{0,1}$/, message: '回收次数需为0-99的数字', trigger: 'blur'}
        ],
        // result: [
        //   { required: true, message: "请输入结果", trigger: "blur" },
        // ],
        sort: [
          { required: true, message: "请输入结果", trigger: "blur" },
        ],
      }
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    //确定
    handleConfirm() {
      const data = {
        m_category: this.form.m_category, //档案类别
        source_type: this.form.source_type, //档案类别
        buy_status: this.form.buy_status, //顾客状态
        short_name: this.form.short_name, //回访级别
        name: this.form.name, //级别含义
        interval_days:this.form.interval_days, //间隔天数
        next_content: this.form.next_content, //下次内容
        recovery_count:this.form.recovery_count, //回收次数
        result:'111', //结果
        sort: this.form.sort, //排序
        use_status:this.form.use_status , //使用状态
      };
      if(isNaN(data.interval_days)){
        this.$message.error("间隔天数需为数字");
        return false;
      }
      if(isNaN(data.recovery_count)){
        this.$message.error("回收次数需为数字");
        return false;
      }
      if(data.m_category == ''){
        this.$message.error("请选择档案类别");
        return;
      }
      if(data.buy_status == ''){
        this.$message.error("请选择购买状态");
        return;
      }
      if(data.recovery_count > 99 && data.recovery_count < -1){
        this.$message.error("回收次数需为0-99的数字");
        return;
      }
      postVisitLevel(data).then((res) => {
        console.log(isNaN(''));
        if (res.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.addStatus = false;
          //刷新
          this.form = {
            m_category: "", //使用状态
            buy_status: "", //顾客状态
            short_name: "", //回访级别
            name: "", //级别含义
            interval_days: "", //间隔天数
            next_content: "", //下次内容
            recovery_count: "", //回收次数
            result: "", //结果
            sort: "", //排序
          };
          this.$emit("getList");
        }else{
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
