<template>
  <div>
    <el-dialog
      title="编辑回访级别"
      :visible.sync="compileStatus"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <!-- //档案类别单选框(radio),购买状态单选框(radio),且都是必填字段 -->

        <el-form-item
          label="线索类型"
          prop="source_type"
          :label-width="formLabelWidth"
        >
          <el-radio-group v-model="form.source_type">
            <el-radio :label="1">电商</el-radio>
            <el-radio :label="2">表单</el-radio>
          </el-radio-group>
        </el-form-item>
        
        <el-form-item
          label="档案类别"
          prop="m_category"
          :label-width="formLabelWidth"
        >
          <el-radio-group v-model="form.m_category">
            <el-radio :label="1">采线</el-radio>
            <el-radio :label="2">运营</el-radio>
            <el-radio :label="3">回访</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="购买状态"
          prop="buy_status"
          :label-width="formLabelWidth"
        >
          <el-radio-group v-model="form.buy_status">
            <el-radio :label="1">未购</el-radio>
            <el-radio :label="2">已购</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="使用状态"
          prop="use_status"
          :label-width="formLabelWidth"
          v-if="form.buy_status == 2"
        >
          <el-radio-group v-model="form.use_status">
            <el-radio :label="1">在用</el-radio>
            <el-radio :label="2">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="回访级别"
          prop="short_name"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.short_name"></el-input>
        </el-form-item>
        <el-form-item
          label="级别含义"
          prop="name"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="间隔天数"
          prop="interval_days"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.interval_days"></el-input>
        </el-form-item>
        <el-form-item
          label="下次内容"
          prop="next_content"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.next_content"></el-input>
        </el-form-item>
        <el-form-item
          label="回收次数"
          prop="recovery_count"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.recovery_count" maxlength="2"></el-input>
        </el-form-item>
				<el-form-item
					label="排序"
					prop="sort"
					:label-width="formLabelWidth"
				>
					<el-input v-model="form.sort"></el-input>
				</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleConfirm" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { putVisitLevel } from "@/api/rank/rank";
export default {
  name: "modifyLevelReturnVisit",
  data() {
    return {
      compileStatus: false,
      form: {
        source_type:'',
				sort:'',
        m_category: "", //档案类别
        buy_status: "", //购买状态
        short_name: "", //回访级别
        name: "", //级别含义
        interval_days: "", //间隔天数
        next_content: "", //下次内容
        use_status: 0, //使用状态
        recovery_count: 0, //回收次数
      },
      formLabelWidth: "120px",
    };
  },
  //监视compileStatus = true时,执行initForm()方法
  watch: {
    compileStatus: function(newVal, oldVal) {
      if (newVal) {
        this.form = this.list;
        console.log(this.form);
      }
    },
    "form.buy_status": function(newVal) {
      console.log(newVal);
      if (newVal == 1) {
        this.form.use_status = 0;
      }
    },
  },
  methods: {
    //关闭弹窗
    handleClose(done) {
      console.log(done);
      done();
      this.$emit("getList");
    },
    handleConfirm() {
      if (isNaN(this.form.interval_days)) {
        this.$message({
          message: "间隔天数必须为数字",
          type: "warning",
        });
        return;
      }
      if (isNaN(this.form.recovery_count)) {
        this.$message({
          message: "回收次数必须为数字",
          type: "warning",
        });
        return;
      }
      if(this.form.recovery_count > 99 && this.form.recovery_count < -1){
        this.$message.error("回收次数需为0-99的数字");
        return;
      }
      this.$confirm("确认提交吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //确认提交
          this.$refs.form.validate((valid) => {
            let data = this.form;
            if (valid) {
              putVisitLevel(data.id, data).then((res) => {
                if (res.code == 200) {
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  //刷新
                  this.$emit("getList");
                  this.compileStatus = false;
                } else {
                  this.$message({
                    type: "error",
                    message: res.msg,
                  });
                }
              });
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    handleCancel() {
      this.compileStatus = false;
      this.$emit("getList");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-form-item__content {
  margin-left: 0px !important;
}
.el-input {
  height: 30px !important;
  width: 40%;
}
::v-deep .el-input__inner {
  height: 30px !important;
}
</style>
