import request from '@/utils/request'

// 商户列表
export function getVisitLevel(params) {
    return request({
      url: 'admin/visitLevel',
      method: 'get',
      params
    })
  }
  //状态管理
  export function postStatus(id,data) {
    return request({
      url: `admin/visitLevel/status/${id}`,
      method: 'POST',
      data,
    })
  }
  //添加回访级别
  export function postVisitLevel(data) {
    return request({
      url: 'admin/visitLevel',
      method: 'POST',
      data: data
    })
  }
  //删除回访级别
  export function deleteVisitLevel(id) {
    return request({
      url: `admin/visitLevel/${id}`,
      method: 'DELETE',
    })
  }
  //修改回访级别
  export function putVisitLevel(id,data) {
    return request({
      url: `admin/visitLevel/${id}`,
      method: 'PUT',
      data: data
    })
  }

  export function getSaleRankApi(params) {
    return request({
      url: `admin/saleRank`,
      method: 'get',
      params
    })
  }
  
  